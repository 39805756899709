<template >
  <div class="blog-preview">
    <Header />
    <div>
      <!-- <h1>{{this.$route.params.url}}</h1>
        {{data}} -->
      <div class="contains-blog">
        <div class="BarMenu">
          <a href="/magazine">
          <p>Magazine</p>            
          </a>
          <div class="Menu">
            <ul>
              <li
                class="op-cat-1 seg"
                :class="{ active_li: liOpc == 'Home',
                  bor_cat6: liOpc == 'Home', }"
                @click="getOption('Home')"
              >
                Home
              </li>
              <li
                class="op-cat-2 seg"
                :class="{ active_li: liOpc == 'MARKETING',
                  bor_cat1: liOpc == 'MARKETING', }"
                @click="getOption('MARKETING')"
              >
                Marketing
              </li>
              <li
                class="op-cat-3 seg"
                :class="{ active_li: liOpc == 'DEVELOPMENT',
                  bor_cat2: liOpc == 'DEVELOPMENT', }"
                @click="getOption('DEVELOPMENT')"
              >
                Development
              </li>
              <li
                class="op-cat-4 seg"
                :class="{ active_li: liOpc == 'MEET THE BOLDS',
                  bor_cat5: liOpc == 'MEET THE BOLDS', }"
                @click="getOption('MEET THE BOLDS')"
              >
                Meet The Bolds
              </li>
              <li
                class="op-cat-5 seg"
                :class="{ active_li: liOpc == 'ENTREPREURNSHIP',
                  bor_cat6: liOpc == 'ENTREPREURNSHIP', }"
                @click="getOption('ENTREPREURNSHIP')"
              >
                Entrepreneurship
              </li>
              <li
                class="op-cat-6 seg"
                :class="{ active_li: liOpc == 'DESIGN',
                  bor_cat3: liOpc == 'DESIGN', }"
                @click="getOption('DESIGN')"
              >
                Design
              </li>
              <li
                class="op-cat-7 seg"
                :class="{
                  active_li: liOpc == 'PHOTOGRAPHY',
                  bor_cat4: liOpc == 'PHOTOGRAPHY',
                }"
                @click="getOption('PHOTOGRAPHY')"
              >
                Photography
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div class="Head" v-if="this.liOpc != 'Londra'"></div>
        <div class="Head" v-else>
          <p class="titulo">{{ data.title }}</p>
          <p class="subtitulo">{{ data.descrp }}</p>
        </div>

        <div class="Contenido">
          <div v-if="this.liOpc != 'Londra'" class="S1 mgz">
            <div class="mkt-mgz">
              <div class="header-c-magazine">
                <p>{{ this.liOpc | split }}</p>
                <!--<div class="return" @click="getOption('Londra')">
                  Regresar a la publicación
                </div>-->
              </div>
            </div>

            <div class="grid-mgz">
              <div v-if="sortedItems(allblogs).length == 0">
                <p class="notfound">
                  No se encontraron items.
                </p>
              </div>
              <div
              v-else 
                class="item-mgz"
                v-for="(item, index) in sortedItems(visibleBlogs(allblogs))"
                :key="index"
              >
              <a href="" v-if="item.statusblog == 'VISIBLE'">
                <div
                  class="image-cont"
                  :style="{
                    'background-image':
                      'url(' +
                      urlpath.url() +
                      '/blog-img/' +
                      item.image +
                      ')',
                  }"
                ></div>    
                <div class="info">
                  <p class="category"
                        :class="{
                          col_cat1: item.category_name == 'MARKETING',
                          col_cat2: item.category_name == 'DEVELOPMENT',
                          col_cat3: item.category_name == 'DESIGN',
                          col_cat4: item.category_name == 'PHOTOGRAPHY',
                          col_cat5: item.category_name == 'MEET THE BOLDS',
                          col_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }">{{ item.category_name | split }}</p>  
                  <p class="title"
                      :class="{
                        hov_cat1: item.category_name == 'MARKETING',
                        hov_cat2: item.category_name == 'DEVELOPMENT',
                        hov_cat3: item.category_name == 'DESIGN',
                        hov_cat4: item.category_name == 'PHOTOGRAPHY',
                        hov_cat5: item.category_name == 'MEET THE BOLDS',
                        hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                      }">{{item.title}}</p> 
                </div>           
              </a>


              </div>
            </div>
          </div>

          <div class="S1" v-else>
            <div class="B1">
              <img :src="this.srcImg(data.image)" />
              <img class="B1img_2" :src="this.srcImg(data.image)" />
              <div class="B1F2">
                <p class="B1Name">
                  Por {{ data.autor_name + " " + data.autor_lastname }}
                </p>
                <p class="B1Date">{{ dayFormat(data.dateblog) }}</p>
                <!-- cambiar formato -->
                <p
                  class="B1Catg"
                  :class="{
                    bg_cat1: data.category_name == 'MARKETING',
                    bg_cat2: data.category_name == 'DEVELOPMENT',
                    bg_cat3: data.category_name == 'DESIGN',
                    bg_cat4: data.category_name == 'PHOTOGRAPHY',
                    bg_cat5: data.category_name == 'MEET THE BOLDS',
                    bg_cat6: data.category_name == 'ENTREPREURNSHIP',
                  }"
                >
                  {{ data.category_name | split }}
                </p>
              </div>

              <!-- cuerpo del blog -->
              <div
                v-for="(bloq, key) in data.body"
                :key="key"
                class="bloq-item"
              >
                <div class="mbbloq">
                  <div
                    v-if="bloq.tipo == 'texto'"
                    v-html="bloq.texto"
                    class="S1F1"
                  ></div>
                </div>

                <div
                  v-if="bloq.tipo == 'video'"
                  v-html="bloq.video"
                  class="video"
                ></div>

                <div v-if="bloq.tipo == 'frase'" class="fraseSq">
                  <p
                    class="B2Mesage"
                    :class="{ B2Mesage2: bloq.tipo != 'frase' }"
                  >
                    “{{ bloq.frase.texto }}”.
                  </p>
                  <p class="B2Autor">/ {{ bloq.frase.firma }}</p>
                </div>

                <div v-if="bloq.tipo == 'frase_destacada'" class="mbbloq">
                  <p class="B4F5_P">"{{ bloq.frase_destacada }}"</p>
                </div>

                <img
                  v-if="bloq.tipo == 'imagen'"
                  :src="srcImg(bloq.imagen)"
                  alt="imagen bloque"
                />
                <div v-if="bloq.tipo == 'caja'" class="B2F1">
                  <div>
                    <div
                      v-if="bloq.caja[0].tipo == 'texto'"
                      v-html="bloq.caja[0].texto"
                      class="B3F1_P"
                    ></div>
                    <img
                      class=""
                      v-if="bloq.caja[0].tipo == 'imagen'"
                      :src="srcImg(bloq.caja[0].imagen)"
                      alt="imagen bloque"
                    />

                    <div v-if="bloq.caja[0].tipo == 'frase'" class="fraseSq">
                      <p class="B2Mesage">“{{ bloq.caja[0].frase.texto }}”.</p>
                      <p class="B2Autor">/ {{ bloq.caja[0].frase.firma }}</p>
                    </div>

                    <div v-if="bloq.caja[0].tipo == 'frase_destacada'">
                      <p class="B3F4_P">"{{ bloq.caja[0].frase_destacada }}"</p>
                    </div>
                  </div>

                  <div class="mlfcaja2">
                    <div
                      v-if="bloq.caja[1].tipo == 'texto'"
                      v-html="bloq.caja[1].texto"
                      class="B3F1_P"
                    ></div>

                    <img
                      v-if="bloq.caja[1].tipo == 'imagen'"
                      :src="srcImg(bloq.caja[1].imagen)"
                      alt="imagen bloque"
                    />

                    <div v-if="bloq.caja[1].tipo == 'frase'" class="fraseSq">
                      <p class="B2Mesage">“{{ bloq.caja[1].frase.texto }}”.</p>
                      <p class="B2Autor">/ {{ bloq.caja[1].frase.firma }}</p>
                    </div>

                    <div v-if="bloq.caja[1].tipo == 'frase_destacada'">
                      <p class="B3F4_P">"{{ bloq.caja[1].frase_destacada }}"</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="data.colaborador != 'No se encontraron coincidencias.'"
              class="B5"
            >
              <img
              class="pc"
                v-if="data.colaborador.image != null"
                :src="this.srcImg2(data.colaborador.image)"
              />
              <div class="B5B2">
                <p class="B5Conoce">
                  Conoce más de <br class="br1"><span>{{ data.colaborador.name }}</span>
                </p>
              <img
              class="mo"
                v-if="data.colaborador.image != null"
                :src="this.srcImg2(data.colaborador.image)"
              />
                <p class="B5Name">{{ data.colaborador.name }}</p>
                <p class="B5Job">{{ data.colaborador.descrp }}</p>
                <!-- pendiente esplit -->
                <!-- <p class="B5Puesto">Fundadora de Demare Pasteles.</p> -->
                <div class="B5Mesage" v-html="data.colaborador.about"></div>
              </div>
            </div>

            <div
              v-if="data.colaborador != 'No se encontraron coincidencias.'"
              class="Social"
            >
              <a
                v-if="data.colaborador.facebook != ''"
                :href="data.colaborador.facebook"
                target="_blank"
              >
                <img class="SocialI" src="../../assets/Facebook.svg"
              /></a>
              <a
                v-if="data.colaborador.instagram != ''"
                :href="data.colaborador.instagram"
              >
                <img class="SocialI" src="../../assets/Instagram.svg"
              /></a>
              <a
                v-if="data.colaborador.twiter != ''"
                :href="data.colaborador.twiter"
              >
                <img class="SocialI" src="../../assets/Twitter.svg"
              /></a>
              <a
                v-if="data.colaborador.linkedin != ''"
                :href="data.colaborador.linkedin"
              >
                <img class="SocialI" src="../../assets/Linkedin.svg"
              /></a>
            </div>

            <div class="B6">
              <p class="B6F1">Tags:</p>
              <div class="B6F2">
                <p v-for="(tag, key) in data.tags" :key="key">
                  #{{ tag.name }}
                </p>
              </div>
            </div>

            <!-- pendiente -->
            <div class="B7">
              <p class="B7Title">Publicaciones relacionadas</p>
              <div
                class="B7F2 blogxcategoria"
                v-if="
                  blogbycategory != 'No se encontraron coincidencias.' &&
                  blogbycategory != 'error'
                "
              >
                <div
                  v-for="(item, index) in blogbycategory.slice(0, 4)"
                  :key="index"
                  class="B7Blogs"
                >
                  <a :href="'/magazine/' + item.url">
                    <!---<img
                    :src="
                      urlpath.url() + '/blog-img/' + item.image
                    "
                  />-->
                    <div
                      class="cont-bl"
                      :style="{
                        'background-image':
                          'url(' +
                          urlpath.url() +
                          '/blog-img/' +
                          item.image +
                          ')',
                      }"
                    ></div>
                    <div>
                      <p
                        class="B7Catg"
                        :class="{
                          col_cat1: item.category_name == 'MARKETING',
                          col_cat2: item.category_name == 'DEVELOPMENT',
                          col_cat3: item.category_name == 'DESIGN',
                          col_cat4: item.category_name == 'PHOTOGRAPHY',
                          col_cat5: item.category_name == 'MEET THE BOLDS',
                          col_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | split }}
                      </p>
                      <!-- cambiar a miniscula -->
                      <p class="B7Text">{{ item.title }}</p>
                      <p class="B7Date">{{ dayFormat2(item.dateblog) }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="B7P">
              <p class="B7Title">Publicaciones relacionadas</p>
              <div
                class="B7F2 blogxcategoria"
                v-if="
                  blogbycategory != 'No se encontraron coincidencias.' &&
                  blogbycategory != 'error'
                "
              >
                <div
                  v-for="(item, index) in blogbycategory"
                  :key="index"
                  class="B7Blogs"
                >
                  <a :href="'/magazine/' + item.url" class="enlace">
                    <img
                      :src="
                        urlpath.url() +
                        '/blog-img/' +
                        item.image
                      "
                    />

                    <div>
                      <p
                        class="B7Catg"
                        :class="{
                          col_cat1: item.category_name == 'MARKETING',
                          col_cat2: item.category_name == 'DEVELOPMENT',
                          col_cat3: item.category_name == 'DESIGN',
                          col_cat4: item.category_name == 'PHOTOGRAPHY',
                          col_cat5: item.category_name == 'MEET THE BOLDS',
                          col_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | split }}
                      </p>
                      <!-- cambiar a miniscula -->
                      <p class="B7Text">{{ item.title }}</p>
                      <p class="B7Date">{{ dayFormat2(item.dateblog) }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="S2">
            <iframe
              class="If1"
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=440&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="100%"
              height="652vw"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>

            <iframe
              class="If2"
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flegrafica%2F&tabs=timeline&width=340&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="600"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
            <img
              alt="img"
              class="image-sponsor"
              src="../../assets/Rectangle64.svg"
            />
            <div class="S2Visto">
              <p class="S2LMV">Lo más visto</p>
              <div
                v-if="
                  blogbytag != 'No se encontraron coincidencias.' &&
                  blogbytag != 'error'
                "
              >
                <div
                  class="item-news"
                  v-for="(item, index) in blogbytag.slice(0, 3)"
                  :key="index"
                >
                  <a :href="'/magazine/' + item.url">
                    <div
                      class="imgLV"
                      :style="{
                        'background-image':
                          'url(' +
                          urlpath.url() +
                          '/blog-img/' +
                          item.image +
                          ')',
                      }"
                    >
                      <!--<img
                      :src="
                        urlpath.url() +
                        '/blog-img/' +
                        item.image
                      "
                    />-->
                    </div>
                    <div class="S2F2">
                      <p
                        class="S2Catg"
                        :class="{
                          col_cat1: item.category_name == 'MARKETING',
                          col_cat2: item.category_name == 'DEVELOPMENT',
                          col_cat3: item.category_name == 'DESIGN',
                          col_cat4: item.category_name == 'PHOTOGRAPHY',
                          col_cat5: item.category_name == 'MEET THE BOLDS',
                          col_cat6: item.category_name == 'ENTREPREURNSHIP',
                        }"
                      >
                        {{ item.category_name | split }}
                      </p>
                      <p class="S2Date">{{ dayFormat2(item.dateblog) }}</p>
                    </div>
                    <p
                      class="S2Text"
                      :class="{
                        hov_cat1: item.category_name == 'MARKETING',
                        hov_cat2: item.category_name == 'DEVELOPMENT',
                        hov_cat3: item.category_name == 'DESIGN',
                        hov_cat4: item.category_name == 'PHOTOGRAPHY',
                        hov_cat5: item.category_name == 'MEET THE BOLDS',
                        hov_cat6: item.category_name == 'ENTREPREURNSHIP',
                      }"
                    >
                      {{ item.title }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Header from "../../components/on-site/Header.vue";
import Footer from "../../components/on-site/Footer.vue";
import dayjs from "dayjs";
import { es } from "dayjs/locale/es";
let urlpath = require("../../global/url");
import { mapActions } from "vuex";
export default {
  name: "previewBlog",
  data() {
    return {
      liOpc: "Londra",
      blogOpc: "Desarrollo",
      urlpath: urlpath,
      destacados: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    let payload = {
      id: this.$route.params.url,
      option: "blog-url",
    };

    await this.getInfoByIdBlo(payload);

    await this.getAllInfoB2("blog");

    let blog = this.$store.getters["blog/data"];
    let payload2 = {
      id: blog.id_category,
      option: "blog-by-category",
    };

    await this.getInfoByIdB3(payload2);

    //obtener blog por etiqueta mas visto.
    await this.getAllInfoTg("tag");
    let etiquetas = this.$store.getters["tag/data"];
    let id_lomasvisto = null;
    for (const item of etiquetas) {
      if (item.name == "LO MAS VISTO") {
        id_lomasvisto = item.id;
      }
    }

    if (id_lomasvisto != null) {
      let payload4 = {
        id: id_lomasvisto,
        option: "blog-by-tag",
      };

      await this.getInfoByIdB4(payload4);
    }
  },
  computed: {
    data() {
      let array = this.$store.getters["blog/data"];

      return array;
    },
    datatitle(){
      return this.data.title
    },
    blogbycategory() {
      let array = this.$store.getters["blog3/data"];
      let encontrados = [];
      for (const item of array) {
        if (item.statusblog == "VISIBLE") {
          encontrados.push(item);
        }
      }
      this.destacado = encontrados;
      return this.destacado;
    },
    blogbytag() {
      let array = this.$store.getters["blog4/data"];
      let encontrados = [];
      for (const item of array) {
        if (item.statusblog == "VISIBLE") {
          encontrados.push(item);
        }
      }
      this.destacado = encontrados;
      return this.destacado;
    },
    allblogs() {
      let array = this.$store.getters["blog2/data"];
      let encontrados = [];
      for (const item of array) {
        if (item.orden != "NA") {
          encontrados.push(item);
        }
      }
      this.destacados = encontrados;
      return array;
    },
    etiquetas() {
      return this.$store.getters["tag/data"];
    },
  },
  methods: {
    ...mapActions("tag", ["getAllInfoTg"]),
    ...mapActions("blog", ["getInfoByIdBlo"]),
    ...mapActions("blog3", ["getInfoByIdB3"]),
    ...mapActions("blog4", ["getInfoByIdB4"]),
    ...mapActions("blog2", ["getAllInfoB2"]),
    visibleBlogs(array){
          array = array.filter(function (n) {
            return n.statusblog == 'VISIBLE';
          });
          return array;
    },
    sortedItems(array) {
      let cat = this.liOpc;
      if (this.liOpc == "Home") {
        this.$router.push("/magazine");
      } else if (this.liOpc != "Home") {
        array = array.filter(function (n) {
          return n.category_name == cat;
        });
      }
      return array;
    },
    getOption: function (option) {
      this.liOpc = option;
    },
    srcImg: function (img) {
      let src = `${urlpath.url()}/blog-img/${img}`;
      return src;
    },
    srcImg2: function (img) {
      //para el colaborador
      let src = `${urlpath.url()}/colaboradores-img/${img}`;
      return src;
    },
    dayFormat(fecha) {
      return dayjs(fecha).format("DD/MM/YYYY");
    },
    dayFormat2(fecha) {
      dayjs.locale("es");
      return dayjs(fecha).format("DD/MMMM/YYYY");
    },
  },

  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      if (len == 1) {
        return splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);
      }

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let titleM = this.$route.params.url
                let title = titleM.replaceAll('-',' ').replaceAll('/','_')
                document.title = to.meta.title || title+' - Legrafica';
            }
        },
  }
};
</script>
<style scoped>
.br1{
  display: none;
}
.pc{
  display: block;
}
.mo{
  display: none;
}
.seg {
  transition: 0.5s;
  border-bottom: 0.3vw solid transparent;
}
.op-cat-1:hover {
  color: var(--entrepreurnship) !important;
  border-color: var(--entrepreurnship) !important;
}
.op-cat-2:hover {
  color: var(--marketing) !important;
  border-color: var(--marketing) !important;
}
.op-cat-3:hover {
  color: var(--development) !important;
  border-color: var(--development) !important;
}
.op-cat-4:hover {
  color: var(--meet) !important;
  border-color: var(--meet) !important;
}
.op-cat-5:hover {
  color: var(--entrepreurnship) !important;
  border-color: var(--entrepreurnship) !important;
}
.op-cat-6:hover {
  color: var(--desing) !important;
  border-color: var(--desing) !important;
}
.op-cat-7:hover {
  color: var(--photography) !important;
  border-color: var(--photography) !important;
}

.item-news:hover .hov_cat1,.item-mgz:hover .hov_cat1 {
  color: var(--marketing) !important;
}
.item-news:hover .hov_cat2,.item-mgz:hover .hov_cat2  {
  color: var(--development) !important;
}
.item-news:hover .hov_cat3,.item-mgz:hover .hov_cat3  {
  color: var(--desing) !important;
}
.item-news:hover .hov_cat4,.item-mgz:hover .hov_cat4  {
  color: var(--photography) !important;
}
.item-news:hover .hov_cat5,.item-mgz:hover .hov_cat5  {
  color: var(--meet) !important;
}
.item-news:hover .hov_cat6,.item-mgz:hover .hov_cat6  {
  color: var(--entrepreurnship) !important;
}

.mlfcaja2 {
  margin-left: 4.427083333333334vw;
}

.mbbloq, .fraseSq {
  margin-bottom: 2.729166666666666vw;
}

.blogxcategoria img {
  width: 24.791666666666668vw;
  height: 16.041666666666668vw;
}
.imgLV {
  width: 20.28125vw;
  height: 8.697916666666666vw;
  margin-top: 2.34375vw;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: center;
  transition: 0.5s;
}
.imgLV:hover {
  background-size: 110%;
}
.imgLV img {
  width: 23.28125vw;
  height: 8.697916666666666vw;
}

.Header {
  display: flex;
  width: 92.5vw;
  height: 10.572916666666666vw;
  padding: 0vw 3.28125vw;
  margin: 0vw;
  justify-content: space-between;
}
.HB1 {
  display: flex;
}
.HB1 img {
  width: 10.260416666666666vw;
  height: auto;
  margin-right: 2.5vw;
  margin-bottom: -6vw;
}
.HB1 p {
  color: #ef67c2;
  font-size: 0.5208333333333333vw;
  align-self: center;
}
.HB2 {
  display: flex;
}
.HB2 a {
  align-self: center;
}
.HB2 p {
  color: #987ef3;
  font-size: 1.1458333333333333vw;
  align-self: center;
  font-family: "Gramatika-Black";
  text-decoration: underline;
  cursor: pointer;
}
.HB2 img {
  width: 3.90625vw;
  height: auto;
  margin-left: 2.5vw;
}

.BarMenu {
  width: 100%;
  height: 4.53125vw;
  display: flex;
  /*border-bottom: 1px solid #ffb6ea;*/
  padding-bottom: 9vw;
}
.BarMenu p {
  color: #ef67c2;
  font-size: 5.208vw;
  margin-top: -1.7vw;
  margin-left: 7.604166666666666vw;
  position: absolute;
}
.Menu {
  width: 100%;
  margin-top: 7.24vw;
}
.Menu ul {
  display: flex;
  margin-top: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  justify-content: space-evenly;
  /*padding-left: 47vw;*/
}
.Menu li {
  font-size: 0.8333333333333334vw;
  /*margin: 0vw 2vw;*/
  margin: 0vw;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: "Gramatika-Bold";
  padding: 1.719vw 0;
  transition: 0.5s;
  border-bottom: 0.3vw solid transparent;
}

.active_li {
  font-size: 0.8333333333333334vw;
  color: #987ef3;
  border-bottom: 0.3vw solid #987ef3;
  padding-bottom: 1.5vw;
}

.Head {
  margin-left: 8.072916666666668vw;
  margin-top: 9.322916666666666vw;
}
p.titulo {
  width: 52vw;
  font-size: 2.34375vw;
  line-height: 2.9296875vw;
  font-weight: 600;
  margin-bottom: 2.2916666666666665vw;
  font-family: "Gramatika-Bold";
}
p.subtitulo {
  width: 37vw;
  font-size: 0.9375vw;
  font-weight: 400;
  line-height: 1.4010416666666665vw;
  font-family: "IBM Plex Mono";
  margin-bottom: 3.125vw;
}

.Contenido {
  display: flex;
  margin-left: 8.072916666666668vw;
  margin-bottom: 32.292vw;
}
/*.Contenido p{
    margin-top: 0;
    margin-bottom: 0;
}*/
.contains-blog {
  padding-top: 19.82421875vh;
}
.B1 {
  margin-bottom: 12.34375vw;
}
.B1 img {
  width: 51.927083333333336vw;
  margin-bottom: 3.28125vw;
}
.B1img_2 {
  display: none;
}
.B1F2 {
  display: flex;
    align-items: center;
  width: 51.927083333333336vw;
  margin-bottom: 6.406249999999999vw;
}
p.B1Name {
  font-size: 0.9375vw;
  font-weight: 600;
  font-family: "Gramatika-Bold";
    line-height: 0.8vw;
  /*margin-top: 0.7vw;*/
}
p.B1Date {
  font-size: 0.9375vw;
    line-height: 0.8vw;
  margin-left: 1vw;
  font-family: "IBM Plex Mono";
}

p.B1Catg {
  display: flex;
  padding-top: 0.2vw;
  padding-bottom: 0.4vw;
  width: 8vw;
  align-items: center;
  justify-content: center;
  font-size: 0.78125vw;
  margin-left: auto;
  background-color: #ef67c2;
  color: #ffffff;
  font-family: "Gramatika-Black";
}

.S1F1 {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.S1F2 {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.S1F3 {
  width: 52vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.S1F4 {
  width: 44vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.S1F5 {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.S1F6 {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}

.B2F1 {
  display: flex;
  margin-bottom: 7.552083333333333vw;
}

.B2F1_p {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B2F2_p {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B2F3_p {
  width: 52vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}

p.B2Mesage {
  width: fit-content;
  height: fit-content;
  font-size: 1.8229166666666667vw;
  font-weight: 600;
  padding-left: 3.854166666666667vw;
  border-left: 0.3vw solid #ffb6ea;
  color: #987ef3;
  font-family: "Gramatika-Bold";
}
.B2Mesage2 {
  width: 16.5vw !important;
}
p.B2Autor {
  font-size: 1.0416666666666665vw;
  font-weight: 500;
  margin-top: 4vw !important;
  padding-left: 3.854166666666667vw;
  color: #987ef3;
  font-family: "IBM Plex Mono";
}
.B2F1 img {
  width: 27.135416666666668vw;
}
.B2img_2 {
  display: none;
}

.B3F2 {
  display: flex;
  width: 51.927083333333336vw;
}

.B3F1_P {
  width: 23vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B3F2_P {
  width: 23vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B3F3_P {
  width: 23vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}

.B3F4_P {
  width: 18vw;
  height: 14vw;
  font-size: 1.8229166666666667vw;
  line-height: 2.278645833333333vw;
  border-left: 0.3vw solid #ffb6ea;
  margin-left: auto;
  padding-left: 3.6458333333333335vw;
  font-family: "Gramatika-Bold";
}

.B3 img {
  width: 52.552083333333336vw;
  margin-top: 5.520833333333333vw;
  margin-bottom: 6.614583333333333vw;
}

.B4F1 {
  margin-top: 6.302083333333333vw;
  margin-bottom: 6.458333333333334vw;
}
.B4I1 {
  width: 25.364583333333336vw;
}
.B4I2 {
  width: 25.364583333333336vw;
  transform: scale(1, 1.15);
  margin-bottom: -1.6vw;
}

.B4F1_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B4F2_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B4F3_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B4F4_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}

.B4F5_P {
  width: 47vw;
  text-align: center;
  font-size: 1.8229166666666667vw;
  font-weight: bold;
  line-height: 2.278645833333333vw;
  margin: 6.458333333333334vw 0vw;
  padding: 5.052083333333333vw 0vw;
  font-family: "Gramatika-Bold";
  border-top: 0.3vw solid #ffb6ea;
  border-bottom: 0.3vw solid #ffb6ea;
}
.B4F6_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}
.B4F7_P {
  width: 51vw;
  font-size: 0.8333333333333334vw;
  font-weight: 400;
  line-height: 1.5875vw;
  margin: 1.8vw 0vw;
  font-family: "IBM Plex Mono";
}

.B5 {
  display: flex;
  /*margin-top: 18.28125vw;*/
}
.B5 img {
  width: 10.572917vw;
  height: 10.572917vw;
  margin-top: 11.111vw;
  margin-bottom: 11.111vw;
  margin-right: 5vw;
  border-radius: 20vw;
}

p.B5Conoce {
  font-size: 1.3541666666666667vw;
  font-weight: 600;
  font-family: "Gramatika-Bold";
  margin-bottom:1.667vw;
}
.B5Conoce span {
  color: #987ef3;
}

p.B5Name {
  font-size: 1.25vw;
  font-weight: 500;
  margin-bottom: 0vw;
  font-family: "Gramatika-Medium";
}

p.B5Job {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin: 0vw;
}
p.B5Puesto {
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  font-family: "IBM Plex Mono";
  margin: 0.3vw 0vw;
}
.B5Mesage {
  width: 35vw;
  font-size: 0.7291666666666666vw;
  font-weight: 400;
  line-height: 1.3343749999999999vw;
  font-family: "IBM Plex Mono";
  margin-top: 2vw;
}
.B5B2 {
  padding-top: 0vw;
    margin: auto;
}

.B6 {
  margin-top: 11.502415458937197vw;
}
.B6F1 {
  font-size: 1.25vw;
  font-family: "Gramatika-Bold";
}
.B6F2 {
  display: flex;
  width: 38vw;
  flex-wrap: wrap;
  margin-bottom: 7.291666666666667vw;
}
.B6F2 p {
  font-size: 1.5625vw;
  color: #ef67c2;
  font-family: "Gramatika-Medium";
  margin: 0vw;
  margin-right: 2vw;
}
.S1 {
  width: 51.927083333333336vw;
}
.S2 {
  margin-left: 7.76vw;
  width: 20.721vw;
}
.S2Visto {
    margin-top: 3.542vw;
}
.S2LMV {
  width: 17.96875vw;
  color: #987ef3;
  font-size: 1.5625vw;
  margin: 3.542vw 0;
  font-weight: 600;
  padding-bottom: 1.25vw;
  border-bottom: 0.3vw solid #ffb6ea;
  font-family: "Gramatika-Bold";
}

.If2 {
  display: none;
}
.S2F2 {
  display: flex;
  margin: 1vw 0vw;
  align-items: center;
}
.S2 iframe {
  margin-left: 0vw;
  margin-bottom: 2.865vw;
}
p.S2Catg {
  color: #ef67c2;
  font-family: "Gramatika-Black";
  margin: 0;
  font-size: 0.781vw;
  line-height: 0.781vw;
}
p.S2Date {
  font-size: 0.5208333333333333vw;
  line-height: 0.5208333333333333vw;
  font-weight: 500;
  /*padding-top: 0.7vw;*/
  margin: 0 0 0 1.5vw;
  font-family: "IBM Plex Mono";
}
p.S2Text {
  width: 20vw;
  color: black;
  font-size: 0.9375vw;
  line-height: 1.171875vw;
  font-weight: 500;
  font-family: "Gramatika-Medium";
}
.image-sponsor {
  width: 100%;
}
.Social {
  margin-top: 3vw;
  margin-left: 15.5vw;
}
.Social img {
  margin-right: 1.6666666666666667vw;
}
.SocialI {
  width: 1.0416666666666665vw;
  height: 1.0416666666666665vw;
}

.B7P {
  display: none;
}
p.B7Title {
  width: 27.395833333333336vw;
  color: #987ef3;
  font-size: 1.5625vw;
  border-bottom: 0.3vw solid #ffb6ea;
  text-align: center;
  margin-left: -3vw;
  margin-bottom: 3.3333333333333335vw;
  padding-bottom: 1.7187500000000002vw;
  font-family: "Gramatika-Bold";
}
.B7Blogs {
  display: block;
}
.B7Blogs .cont-bl {
    background-color: #ffb6ea;
  margin-right: 0.2vw;
}
.B7Blogs .cont-bl {
  width: 24.792vw;
  height: 16.042vw;
  background-size: 30.792vw;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
.B7Blogs:hover .cont-bl {
  background-size: 35.792vw;
}
.B7F2 {
  width: 55vw;
  display: flex;
  flex-wrap: wrap;
}
p.B7Catg {
  color: #ef67c2;
  font-size: 0.78125vw;
  font-weight: 700;
  font-family: "Gramatika-Black";
}
p.B7Text {
  font-size: 1.25vw;
  width: 25vw;
  font-weight: 500;
  color: #000000;
  font-family: "Gramatika-Medium";
}
p.B7Date {
  color: #b2acfc;
  font-size: 0.5208333333333333vw;
  font-weight: 500;
  margin-bottom: 3.4895833333333335vw;
  font-family: "IBM Plex Mono";
}

.header-c-magazine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.167vw;
}
.return {
    color: #aeaeae;
  font-family: "Gramatika-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 121.3%;
  cursor: pointer;
}
.header-c-magazine p {
  font-family: "Gramatika-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 3.125vw;
  line-height: 121.3%;
  color: #987ef3;
  margin: 0;
}
.grid-mgz {
  display: flex;
  flex-wrap: wrap;
}
.image-cont {
  width: 100%;
  height: 15.885vw;
  background-size: 170%;
  background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
    background-color: #ffb6ea;
}
.item-mgz:hover .image-cont {
  background-size: 180%;
}
.S1.mgz {
    width: 56.208vw;
}
.item-mgz {
  width: 18.229vw;
  margin: 0 0.313vw 0 0;
}
.item-mgz p.category {
    font-family: 'Gramatika-Black';
    font-style: normal;
    font-weight: 400;
    font-size: 0.781vw;
    line-height: 103.8%;
    color: #EF67C2;
    margin: 0 0 1.302vw;
}
.item-mgz p.title {
    font-family: 'Gramatika-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1.302vw;
    line-height: 125%;
    color: #231F20;
    margin: 0;
    transition: .5s;
}
.item-mgz .info {
    margin: 2.292vw 0;
}
p.notfound {
    font-family: 'Gramatika-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 1.875vw;
    line-height: 125%;
    color: #bababa;
    margin: 0;
}
@media (max-width: 768px) {
.br1{
  display: block;
}
.pc{
  display: none;
}
.mo{
  display: block;
}
  .S2Visto {
    display: none;
  }

  .Header {
    display: flex;
    width: 86.5vw;
    height: 28.531400966183575vw;
    padding: 0vw 3.28125vw;
    margin: auto;
    justify-content: space-between;
  }
  .HB1 img {
    width: 42.99516908212561vw;
    height: auto;
    margin-right: 2.5vw;
    margin-bottom: -29vw;
  }
  .HB1 p {
    display: none;
  }
  .HB2 a {
    display: none;
  }
  .HB2 img {
    width: 14.009661835748794vw;
    height: auto;
    margin-left: 2.5vw;
  }
.Menu {
    width: 100%;
    margin-top: -2.76vw;
}
  .BarMenu {
    width: 100%;
    height: 9.420289855072465vw;
    display: flex;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: 14vw;
    padding-bottom: 0vw;
  }
  .BarMenu p {
    color: #ef67c2;
    font-size: 9.66183574879227vw;
    margin-top: -16.7vw;
    margin-left: 7.604166666666666vw;
    position: relative;
  }
  .Menu ul {
    display: flex;
    margin-top: 2.5vw;
    width: 100vw;
    overflow-x: auto;
    margin-left: -65vw;
  }
  .Menu li {
    font-size: 3.140096618357488vw;
    margin: 0vw 3.125vw;
    list-style: none;
    cursor: pointer;
    white-space: nowrap;
  }
  .active_li {
    color: #987ef3;
    border-bottom: 0.3vw solid #987ef3;
    padding-bottom: 2.1vw;
  }

  .Contenido {
    display: block;
    margin-left: 7.135vw;
  }

  p.titulo {
    width: 83vw;
    font-size: 5.314009661835748vw;
    line-height: 6.642512077294686vw;
    font-weight: 600;
    margin-bottom: 7.2916666666666665vw;
  }
  p.subtitulo {
    width: 72vw;
    font-size: 2.657004830917874vw;
    font-weight: 400;
    line-height: 3.973429951690821vw;
    font-family: "IBM Plex Mono";
    margin-bottom: 3.125vw;
  }

  .B1 img {
    display: none;
  }
  img.B1img_2 {
    display: block !important;
    width: 83.81642512077295vw;
    margin-bottom: 3.28125vw;
  }
  .B1F2 {
    display: flex;
    width: 83.81642512077295vw;
    margin-bottom: 14.009661835748794vw;
  }
  p.B1Name {
    font-size: 2.1739130434782608vw;
    margin-top: 1.893vw;
  }
  p.B1Date {
    font-size: 2.1739130434782608vw;
    margin-left: 2vw;
  }
  p.B1Catg {
    /*width: 11.11111111111111vw;
    height: 3.140096618357488vw;*/
    width: auto;
    height: auto;
    font-size: 1.4492753623188406vw;
    line-height: 1vw;
    margin-top: 2.1vw;
    padding: 1.449vw 4.348vw;
  }

  .S1F1 {
    width: 76vw;
    font-size: 2.657vw;
    /*line-height: 4.142512077294685vw;*/
    line-height: 190.5%;
   /* margin: 3vw 0vw;*/
    margin: 0;
  }
  .S1F2 {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }
  .S1F3 {
    width: 76vw;
    /*font-size: 2.1739130434782608vw;*/
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .S1F4 {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .S1F5 {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .S1F6 {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }

  /***ql-align-justify */
  ::v-deep .S1F1 > p, ::v-deep .S1F2 > p, ::v-deep .S1F3 > p,::v-deep .S1F4 >  p,::v-deep .S1F5 > p,::v-deep .S1F6 > p {
    margin: 0!important;
  }
  ::v-deep .S1F1 > p.ql-align-justify, 
  ::v-deep .S1F2 > p.ql-align-justify, 
  ::v-deep .S1F3 > p.ql-align-justify,
  ::v-deep .S1F4 > p.ql-align-justify,
  ::v-deep .S1F5 > p.ql-align-justify,
  ::v-deep .S1F6 > p.ql-align-justify {
    margin: 5.6vw 0!important;
  }
  ::v-deep .S1F1 > p span, 
  ::v-deep .S1F2 > p span, 
  ::v-deep .S1F3 > p span,
  ::v-deep .S1F4 > p span,
  ::v-deep .S1F5 > p span,
  ::v-deep .S1F6 > p span {
    margin: 5.6vw 0!important;
  }
  ::v-deep .S1F1 > p br,::v-deep .S1F2 > p br,::v-deep .S1F3 > p br,::v-deep .S1F4 > p br,::v-deep .S1F5 > p br,::v-deep .S1F6 > p br{
    content: "   ";
  }

  .B2F1 {
    display: block;
    margin-bottom: 7.552083333333333vw;
  }
  p.B2Mesage {
    width: 57vw;
    height: fit-content;
    font-size: 5.314009661835748vw;
    line-height: 6.642512077294686vw;
    padding-left: 3.854166666666667vw;
    border-left: 1.2vw solid #ffb6ea;
    margin:28.261vw 0 8.261vw;
  }
  p.B2Autor {
    font-size: 2.898550724637681vw;
    margin: 0 0 28.261vw;
    padding-left: 3.854166666666667vw;
  }
  .B2F1 img {
    display: none;
  }
  .B2img_2 {
    display: block !important;
    width: 83.57487922705315vw !important;
    margin-left: 0vw !important;
  }

  .B2F1_p {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .B2F2_p {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .B2F3_p {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }

  .B3 img {
    width: 80.43478260869566vw;
    margin-top: 11.908213vw;
    margin-bottom: 12.560386473429952vw;
  }
  .B3F2 {
    display: block;
    width: 79.927083vw;
  }
  .B3F1_P {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .B3F2_P {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .B3F3_P {
    width: 76vw;
    font-size: 2.657vw;
    line-height: 190.5%;
    margin: 3vw 0vw;
  }
  .B3F4_P {
    width: 47vw;
    height: 53.5vw;
    font-size: 5.314009661835748vw;
    line-height: 6.642512077294686vw;
    border-left: 1.2vw solid #ffb6ea;
    margin-left: inherit;
    margin-top: 18.357487922705314vw;
    padding-left: 11.352657004830919vw;
    font-family: "Gramatika-Bold";
  }
  
  ::v-deep .B3F1_P > p,::v-deep .B3F2_P > p,::v-deep .B3F3_P > p,::v-deep .B3F4_P > p{
    margin: 0!important;
  }
  ::v-deep .B3F1_P > p.ql-align-justify, 
  ::v-deep .B3F2_P > p.ql-align-justify, 
  ::v-deep .B3F3_P > p.ql-align-justify,
  ::v-deep .B3F4_P > p.ql-align-justify {
    margin: 5.6vw 0!important;
  }
  ::v-deep .B3F1_P > p br,::v-deep .B3F2_P > p br,::v-deep .B3F3_P > p br,::v-deep .B3F4_P > p br{
    content: " ";
  }
.mlfcaja2 {
  margin: 0;
}
  .B4F1 {
    margin-top: 18.357487922705314vw;
    margin-bottom: 6.458333333333334vw;
  }
  .B4I1 {
    width: 80.43478260869566vw;
    margin-bottom: 2.4154589371980677vw;
  }
  .B4I2 {
    width: 80.43478260869566vw;
  }

  .B4F1_P {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }
  .B4F2_P {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }
  .B4F3_P {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }
  .B4F4_P {
    display: none;
  }
  .B4F3_P span {
    display: none;
  }

  .B4F5_P {
    width: 65vw;
    font-size: 5.314009661835748vw;
    line-height: 6.642512077294686vw;
    margin: 19.082125603864732vw 0vw;
    padding: 19.082125603864732vw 0vw;
    border-top: 1vw solid #ffb6ea;
    border-bottom: 1vw solid #ffb6ea;
    margin-left: 8vw;
  }

  .B4F6_P {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }
  .B4F7_P {
    width: 76vw;
    font-size: 2.1739130434782608vw;
    line-height: 4.142512077294685vw;
    margin: 3vw 0vw;
  }

  .B5 {
    display: block;
    margin-top: 51.93236714975845vw;
  }
  .B5 img {
    width: 54.589371980676326vw;
    height: 54.589371980676326vw;
    border-radius: 30vw;
    margin-right: 0vw;
    margin-left: 15vw;
  }
  .S1 {
    width: 82.927083vw;
  }
  p.B5Conoce {
    width: auto;
    font-size: 5.314009661835748vw;
    line-height: 6.642512077294686vw;
    text-align: center;
    margin: auto;
}
  p.B5Name {
    width: 82vw;
    text-align: center;
    font-size: 4.830917874396135vw;
    font-weight: 500;
    margin-bottom: 0vw;
    margin-top: 0;
    font-family: "Gramatika-Medium";
  }
  p.B5Job {
    width: 82vw;
    text-align: center;
    font-size: 2.4154589371980677vw;
    font-weight: 400;
    font-family: "IBM Plex Mono";
    margin: 0vw;
  }
  p.B5Puesto {
    width: 82vw;
    text-align: center;
    font-size: 2.4154589371980677vw;
    font-weight: 400;
    font-family: "IBM Plex Mono";
    margin: 0.3vw 0vw;
  }
  .B5Mesage {
    width: 76vw;
    text-align: center;
    font-size: 2.1739130434782608vw;
    font-weight: 400;
    line-height: 3.9782608695652173vw;
    font-family: "IBM Plex Mono";
    margin-top: 2vw;
    margin-left: 2vw;
  }
  .B5B2 {
    padding-top: 0vw;
    margin: auto;
  }

  .B6 {
    margin-top: 20.502415458937197vw;
  }
  .B6F1 {
    font-size: 3.6231884057971016vw;
  }
  .B6F2 {
    width: 68vw;
    margin-bottom: 13.81642512077295vw;
  }
  .B6F2 p {
    font-size: 3.140096618357488vw;
    margin-right: 2.9vw;
  }

  .Social {
    width: 57vw;
    text-align: center;
  }
  .Social img {
    margin-right: 7.666667vw;
  }
  .SocialI {
    width: 3.589372vw;
    height: 3.589372vw;
  }

  .enlace {
    display: flex;
  }
  .B7 {
    display: none;
  }
  .B7P {
    display: block;
  }
  p.B7Title {
    width: 68.84057971014492vw;
    font-size: 4.3478260869565215vw;
    border-bottom: 1vw solid #ffb6ea;
    margin-left: 0vw;
    margin-bottom: 11.594202898550725vw;
    padding-bottom: 3.864734299516908vw;
  }
  .B7Blogs {
    display: flex;
    margin-bottom: 1.932367149758454vw;
    padding-bottom: 1.932367149758454vw;
    border-bottom: 0.1vw solid #e4e4f9;
  }
  .B7Blogs img {
    width: 39.85507246376812vw;
    height: 27.053140096618357vw;
    margin-right: 7.246376811594203vw;
    object-fit: cover;
  }
  p.B7Catg {
    color: #ef67c2;
    font-size: 1.932367149758454vw;
    font-weight: 700;
    font-family: "IBM Plex Mono";
  }
  p.B7Text {
    font-size: 3.140096618357488vw;
    width: 36vw;
    font-weight: 500;
    color: #000000;
    font-family: "Gramatika-Medium";
  }
  p.B7Date {
    color: #b2acfc;
    font-size: 1.932367149758454vw;
    font-weight: 500;
    margin-bottom: 3.4895833333333335vw;
    font-family: "IBM Plex Mono";
  }

  .If1 {
    display: none;
  }
  .If2 {
    display: block;
  }
  .S2 iframe {
    margin-left: -8.5vw;
  }
  .S2 img {
    width: 87.92270531400966vw;
    margin-left: -8.5vw;
  }
  .S2Visto img {
    width: 47vw;
    margin-top: 2.34375vw;
  }



.S1.mgz {
    width: 80.208vw;
}
  .item-mgz {
    width: 100%;
    margin: 0 0.313vw 0 0;
}
.image-cont {
    width: 39.855vw;
    height: 27.053vw;
}
.item-mgz a {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.657vw;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 2.657vw;
}
.item-mgz .info {
    width: 38.406vw;
}
.item-mgz p.category {
    font-size: 1.932vw;
    margin-bottom: 4.831vw;
}
.item-mgz p.title {
    font-weight: 400;
    font-size: 3.140vw;
}
.header-c-magazine p {
    font-size: 8.125vw;
}
.header-c-magazine {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.return {
    font-size: 2.938vw;}
}
</style>
